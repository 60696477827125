:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

	.defaultButtonStyling {
		@apply flex justify-center text-sm items-center text-white bg-gradient-to-r from-indigo-600 to-indigo-400 px-4 py-2 border border-blue-800 rounded-md hover:bg-indigo-700 hover:shadow-indigo-400 hover:shadow-md hover:border-[#D0BCFF] transition ease-in-out
	}

	.defaultButtonApproveStyling {
		@apply flex justify-center items-center bg-slate-950 text-green-400 px-4 py-2 border border-green-600 rounded-md hover:bg-gray-900 hover:border-green-400 transition ease-in-out
	}

	.defaultButtonCancelStyling {
		@apply flex justify-center items-center bg-slate-950 text-orange-600 px-4 py-2 border border-orange-800 rounded-md hover:bg-gray-900 hover:border-[#D0BCFF] transition ease-in-out
	}

	.defaultButtonErrorStyling {
		@apply flex justify-center items-center bg-red-800 text-[#D0BCFF] px-4 py-2 border border-blue-800 rounded-md hover:bg-indigo-700 hover:border-[#D0BCFF] transition ease-in-out
	}

	.defaultButtonSuccessStyling {
		@apply flex justify-center items-center bg-green-800 text-white px-4 py-2 border border-white rounded-md hover:bg-indigo-700 hover:border-[#D0BCFF] transition ease-in-out
	}

	.defaultInputStyling {
		@apply px-4 py-1 text-black rounded-sm
	}

	.miniButtonActiveStyling {
		@apply flex justify-between px-2 py-1 items-center border-blue-400 bg-slate-900 max-h-[68px] border shadow hover:border-blue-200 hover:shadow-black ease-in-out
	}

	.miniButtonStyling {
		@apply flex justify-between px-2 py-1 items-center text-white max-h-[68px] bg-black border-white border hover:shadow-blue-400 hover:shadow hover:shadow-sm hover:border-blue-400 ease-in-out
	}

	.miniButtonCancelStyling {
		@apply flex justify-between px-2 py-1 items-center text-white max-h-[68px] bg-black border-orange-400 border hover:shadow-orange-400 hover:shadow hover:shadow-sm hover:border-orange-400 ease-in-out
	}

	.roundedButtonStyling {
		@apply border border-indigo-400 rounded-md px-2 hover:bg-indigo-900 hover:shadow-md hover:shadow-indigo-900
	}

	.roundedButtonActiveStyling {
		@apply bg-indigo-900 hover:bg-indigo-900 hover:shadow-md border border-indigo-400 rounded-md px-2
	}

	/* ... */
}

body {
	color: rgb(var(--foreground-rgb));
	background: linear-gradient(to bottom,
			transparent,
			rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

* {
	-ms-overflow-style: none;
}

::-webkit-scrollbar {
	display: none;
}

/* Background animation */
.background-animate {
	background-size: 400%;
	-webkit-animation: AnimationName 4s ease infinite;
	-moz-animation: AnimationName 4s ease infinite;
	animation: AnimationName 4s ease infinite;
}

@keyframes AnimationName {

	0%,
	100% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 20%;
	}
}


/* React Calendar component */
.react-calendar__tile {
	background-color: #1e1b4b;
	color: #D0BCFF;
	border: 1px solid #35476e;
}

.react-calendar__tile:hover {
	background-color: #2563eb;
}

/* Landing page styling */
.reveal {
	position: relative;
	transform: translateY(150px);
	opacity: 0;
	transition: 1s all ease;
}

.reveal.active {
	transform: translateY(0);
	opacity: 1;
}

/* Background Image wrapper */
.bg-image-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}